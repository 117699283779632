import { getCurrentUTCTime, getDate, getTicketsList } from "../utils/Utilities";

export interface TicketProps {
    flyer: string;
    goTo: string;
    ticketLink: string;
    ticketEvent: string;
    tablesLink: string;
    locationLink: string;
    showSince: string;
    showUntil: number;
}

interface IntroTextsProps {
    wantMyTicketAndMoreInfoIntroduction: TicketProps;
}

interface GlobalTextsProps {
    tickets: TicketProps[];
    whatsAppGroupLink: string;
    instagramLink: string;
    tiktokLink: string;
    twitterLink: string;
    spotifyLink: string;
    picturesLink: string;
}

export const globalTexts: GlobalTextsProps = {
    tickets: [
        {
            flyer: '251024-groove.webp',
            goTo: '251024-groove',
            ticketLink: 'https://www.passline.com/eventos/fiebre-fiesta-en-groove-2510',
            ticketEvent: '25/10/24_groove',
            tablesLink: 'https://wa.me/message/FFDFCLWPF7KPB1',
            locationLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.9688531391407!2d-58.4257572874254!3d-34.57965465611708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb59b4e07a685%3A0x57be92636048e0f9!2sGroove!5e0!3m2!1ses!2sar!4v1720909728659!5m2!1ses!2sar',
            showSince: '2024-09-24T22:59:00Z',
            showUntil: 20241025
        },
        {
            flyer: '011124-black.webp',
            goTo: '011124-black',
            ticketLink: 'https://www.passline.com/eventos/fiebre-halloween-edition-en-black-111',
            ticketEvent: '01/11/24_black',
            tablesLink: 'https://wa.me/message/FFDFCLWPF7KPB1',
            locationLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3285.874584080714!2d-58.4483721!3d-34.556730699999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb5eb0d0f6a89%3A0x64f7cfb36b48c654!2sBlack!5e0!3m2!1ses!2sar!4v1716311869803!5m2!1ses!2sar',
            showSince: '2024-09-24T22:59:00Z',
            showUntil: 20241101
        },
        {
            flyer: '151124-black.webp',
            goTo: '151124-black',
            ticketLink: 'https://www.passline.com/eventos/fiebre-fiesta-en-black-1511',
            ticketEvent: '15/11/24_black',
            tablesLink: 'https://wa.me/message/FFDFCLWPF7KPB1',
            locationLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3285.874584080714!2d-58.4483721!3d-34.556730699999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb5eb0d0f6a89%3A0x64f7cfb36b48c654!2sBlack!5e0!3m2!1ses!2sar!4v1716311869803!5m2!1ses!2sar',
            showSince: '2024-11-02T14:59:00Z',
            showUntil: 20241115
        },
        {
            flyer: '161124-santa-rosa.webp',
            goTo: '161124-santa-rosa',
            ticketLink: 'https://www.passline.com/eventos/fiebre-tour-santa-rosa-1611',
            ticketEvent: '16/11/24_santa_rosa',
            tablesLink: 'https://wa.me/message/FFDFCLWPF7KPB1',
            locationLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3202.3191451919756!2d-64.29430218735557!3d-36.61870386657587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95c2cd5b5321ffe5%3A0x2c4a7e3e7c8af2b9!2sAFRA%20Tap%20House!5e0!3m2!1ses!2sar!4v1730331770523!5m2!1ses!2sar',
            showSince: '2024-09-24T22:59:00Z',
            showUntil: 20241116
        },
        {
            flyer: '221124-groove.webp',
            goTo: '221124-groove',
            ticketLink: 'https://www.passline.com/eventos/fiebre-fiesta-en-groove-2211',
            ticketEvent: '22/11/24_groove',
            tablesLink: 'https://wa.me/message/FFDFCLWPF7KPB1',
            locationLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.9688531391407!2d-58.4257572874254!3d-34.57965465611708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcb59b4e07a685%3A0x57be92636048e0f9!2sGroove!5e0!3m2!1ses!2sar!4v1720909728659!5m2!1ses!2sar',
            showSince: '2024-11-10T22:29:00Z',
            showUntil: 20241122
        },
    ],
    whatsAppGroupLink: 'https://chat.whatsapp.com/DvTmBLdUjh4KGyb4g3ATdq',
    instagramLink: 'https://www.instagram.com/fiebreflow/',
    tiktokLink: 'https://www.tiktok.com/@fiebre.fiesta',
    twitterLink: 'https://twitter.com/fiebrefiesta',
    spotifyLink: 'https://open.spotify.com/playlist/1H3SYVXxljbApZxgIWxh2U?si=a54WonX9SryzjMi49JSEGg&pi=u-fMQTIWKFRTay',
    picturesLink: 'https://drive.google.com/drive/folders/1dlI4mi7Fxb6Z0O9mF5-LkmpyalajPLyx?usp=share_link'
}

const getInfoVisible = (list: TicketProps[]) => {

    let index = 0

    const targetTimeUTC = new Date(list[index].showSince);

    const currentTimeUTC = new Date(getCurrentUTCTime());

    if ((list[index].showUntil < parseInt(getDate())) || ((currentTimeUTC < targetTimeUTC))) {
        index++
    }

    return list[index]

}

export const introTexts: IntroTextsProps = {
    wantMyTicketAndMoreInfoIntroduction: getInfoVisible(globalTexts.tickets),
    // wantMyTicketAndMoreInfoIntroduction: globalTexts.tickets[0],
}